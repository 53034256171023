import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import AccordionCustom from 'components/common/AccordionCustom';
import { IFaqs } from './models';
import './Faqs.scss';

const Faqs: FC<IFaqs> = ({ faqs, faqTitle }) => {
  const fagsItems =
    faqs && faqs?.length > 3 ? (
      <AccordionCustom data={faqs} />
    ) : (
      <ul className="dt-faqs__faqs-list">
        {faqs?.map(({ properties: { question, answer } }, iter) => (
          <li key={`dt-faqs__${iter}`}>
            <h3 className="dt-faqs__sub-title">{question}</h3>
            <DangerouslySetInnerHtml className="dt-faqs__text" html={answer} />
          </li>
        ))}
      </ul>
    );

  return (
    <div className="dt-faqs">
      <h2 className="dt-faqs__title">{faqTitle || 'FAQ’S'}</h2>
      {fagsItems}
    </div>
  );
};

export default Faqs;
